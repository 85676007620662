/**
 * コードグループ
 */
export const CODE_GROUP = {
  EXEC: "EXEC",
  EXISTE: "EXISTE",
  ACTUAL: "ACTUAL",
  CHANGE: "CHANGE",
  CONFIR: "CONFIR",
  GAS: "GAS",
  MEAL: "MEAL",
  SALMIN: "SALMIN",
  VALID: "VALID",
  AUTHOR: "AUTH",
  WWS: "WWS",
  GASCAR: "GASCAR",
  CARESH: "CARESH",
  CAREKB: "CAREKB",
  CARETM: "CARETM",
  DIVISI: "DIVISI",
  APPRVL: "APPRVL",
  TZONE: "TZONE",
  FCAR: "FCAR",
  OVER: "OVER"
};

export const NOT_SET_CODE = {
  CHANGE: "99",
  EXISTE: "99"
};

export const BUSYO_CODE = {
  BUILDING: "1",
  FOOD: "2",
  WELFARE: "3",
  GENERAL: "9"
};

export const COMMON_SHIFT_PATTERNS_REF = "99999";

export const CARE_SHUBETSU = {
  EMERGENCY_FLAG: "000010",
  MIDNIGHT_TEL: "000011"
};

export const DATE_FORMAT = {
  DATE: "YYYY-MM-DD",
  DATE_MINUTE: "YYYY-MM-DD HH:mm",
  JP_DATE: "YYYY年MM月DD日"
} as const;
